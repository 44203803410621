/** @jsx jsx */
import { jsx, Label, Input } from "theme-ui"

interface CompareCheckboxProps {
  checked: boolean
  className?: string
  id: string
  name: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
  small?: boolean
  text?: string
  value: string
}

const CompareCheckbox = ({
  checked,
  className,
  id,
  name,
  onChange,
  small,
  text,
  value,
}: CompareCheckboxProps) => {
  return (
    <Label
      className={className}
      htmlFor={id}
      sx={{
        cursor: "pointer",
        display: "inline-flex",
        alignItems: "flex-start",
        width: "fit-content",
        ml: "5px",
      }}
    >
      <Input
        checked={checked}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        type="checkbox"
        variant="styles.srOnly"
        sx={{
          ":checked ~ span > span": {
            backgroundColor: "primary",
          },
          ":focus ~ span": {
            outline: "2px solid black",
          },
        }}
      />

      <span
        sx={{
          border: "2px solid #A18347",
          display: "block",
          padding: "3px",
          height: small ? "20px" : "25px",
          width: small ? "20px" : "25px",
        }}
      >
        <span
          sx={{
            backgroundColor: "transparent",
            display: "block",
            height: small ? "10px" : "15px",
            transition: "all 0.3s",
            width: small ? "10px" : "15px",
          }}
        ></span>
      </span>

      {text && (
        <span
          sx={{
            alignSelf: "center",
            color: "text",
            fontFamily: "body",
            fontSize: 0,
            fontWeight: "normal",
            lineHeight: "100%",
            ml: 4,
            textTransform: "uppercase",
          }}
        >
          {text}
        </span>
      )}
    </Label>
  )
}

export default CompareCheckbox
