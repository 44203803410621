/** @jsxRuntime classic */
/** @jsx jsx */
import { graphql, PageProps } from "gatsby"
import { jsx, Box, Heading, Container, Grid, Themed } from "theme-ui"
import { useAtom } from "jotai"
import { useMemo, useReducer, useEffect } from "react"
import {
  getQueryStringObj,
  createQueryString,
  setQueryStringWithoutPageReload,
} from "helpers"

import type { AmmoCategoryTemplateQuery } from "../../graphql-types"
import { displayListAtom, ammoFiltersAtom } from "../atoms"

import AguilaButton from "components/AguilaButton"
import AmmoFilter from "components/ammo-filter/AmmoFilter"
import CategoryHero from "components/CategoryHero"
import Layout from "components/Layout"
import MdContent from "components/common/MdContent"
import ProductCard from "components/ProductCard"
import SectionHeading from "components/SectionHeading"

const filterInitialState = {
  allFilters: [],
  calibers: [],
  bullet_type: [],
  application: [],
  weight: [],
  shot_size: [],
  velocity: [],
}

const getInitialStateFromURL = () => {
  if (typeof window === "undefined") return filterInitialState
  if (window.location.search === "") return filterInitialState

  const currentQueryString = getQueryStringObj(window.location.search)
  const fixedInitialState = {
    allFilters: [],
    calibers: [],
    bullet_type: [],
    application: [],
    weight: [],
    shot_size: [],
  }

  for (const [key, value] of Object.entries(currentQueryString)) {
    if (key in fixedInitialState) {
      const keyValues = [...new Set(value.split(","))]
      fixedInitialState[key] = keyValues
      for (let i = 0; i < keyValues.length; i++) {
        const val = keyValues[i]
        fixedInitialState.allFilters.push({
          value: val,
          filterName: key,
        })
      }
    }
  }

  return fixedInitialState
}

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "addFilter":
      if (state[action.filterName]) {
        state[action.filterName].push(action.value)
        state.allFilters.push({
          value: action.value,
          filterName: action.filterName,
        })
        return {
          ...state,
        }
      } else {
        state.allFilters.push({
          value: action.value,
          filterName: action.filterName,
        })
        return {
          ...state,
          [action.filterName]: [action.value],
        }
      }

    case "removeFilter":
      const allFilter = state.allFilters
      const allIndex = allFilter.findIndex((x: any) => x.value === action.value)
      if (allIndex > -1) {
        allFilter.splice(allIndex, 1)
      }

      const filter = state[action.filterName]
      const index = filter.indexOf(action.value)
      if (index > -1) {
        filter.splice(index, 1)
      }
      return { ...state }
    case "clearFilter":
      return {
        allFilters: [],
        calibers: [],
        bullet_type: [],
        application: [],
        weight: [],
        shot_size: [],
        velocity: [],
      }
    default:
      throw new Error()
  }
}

interface AmmoCategoryProps extends PageProps {
  data: AmmoCategoryTemplateQuery
}

const AmmoCategory = ({ data, path, ...props }: AmmoCategoryProps) => {
  const isShotShellPage = path.includes("shotshell")
  const [state, dispatch] = useReducer(
    reducer,
    filterInitialState,
    getInitialStateFromURL
  )

  const [displayList, setDisplayList] = useAtom(displayListAtom)

  const handleDisplayChange = () => setDisplayList(displayList => !displayList)

  useEffect(() => {
    const filtersSelected = { ...state }

    for (const [key, value] of Object.entries(filtersSelected)) {
      if (value.length === 0 || key === "allFilters") {
        delete filtersSelected[key]
      }
    }

    const qs = createQueryString(filtersSelected)
    setQueryStringWithoutPageReload(`?${qs}`)
  }, [state])

  useEffect(() => {
    let timeout: NodeJS.Timer
    const handleResize = () => {
      window.matchMedia("(max-width: 768px)").matches && setDisplayList(false)
    }

    const debouncedHandleResize = () => {
      clearTimeout(timeout)
      timeout = setTimeout(handleResize, 100)
    }

    window.addEventListener("resize", debouncedHandleResize)

    return () => window.removeEventListener("resize", debouncedHandleResize)
  }, [])

  const createFilterArr = (data: any, id: string) => {
    return data.map((item: string, index: number) => ({
      name: item,
      id: `${id}-${index}`,
    }))
  }

  const weightOptions = createFilterArr(data.UniqueWeights?.distinct, "weight")

  const velocityOptions = createFilterArr(
    data.UniqueVelocities?.distinct,
    "velocity"
  )

  const caliberOptions = createFilterArr(
    data.UniqueCalibers?.distinct,
    "caliber"
  )

  const bulletTypeOptions = createFilterArr(
    data.UniqueBulletTypes?.distinct,
    "bullet_type"
  )

  const applicationOptions = createFilterArr(
    data.UniqueApplications?.distinct,
    "application"
  )

  const shotSizeOptions = createFilterArr(
    data.UniqueShotSizes?.distinct,
    "shot_size"
  )

  const FilterData = isShotShellPage
    ? [
        {
          name: "gauge",
          id: "calibers",
          filterOptions: caliberOptions,
        },
        {
          name: "shot Size",
          id: "shot_size",
          filterOptions: shotSizeOptions,
        },
        {
          name: "weight",
          id: "weight",
          filterOptions: weightOptions,
        },
        {
          name: "velocity",
          id: "velocity",
          filterOptions: velocityOptions,
        },
        {
          name: "application",
          id: "application",
          filterOptions: applicationOptions,
        },
      ]
    : [
        {
          name: "caliber",
          id: "calibers",
          filterOptions: caliberOptions,
        },
        {
          name: "bullet types",
          id: "bullet_type",
          filterOptions: bulletTypeOptions,
        },
        {
          name: "weight",
          id: "weight",
          filterOptions: weightOptions,
        },
        {
          name: "application",
          id: "application",
          filterOptions: applicationOptions,
        },
      ]

  const filteredProducts = useMemo(() => {
    if (state.allFilters.length === 0) return data.allStrapiProduct?.edges

    let filteredData = data.allStrapiProduct?.edges.filter((product: any) => {
      const { calibers } = product.node

      if (calibers === null) return false
      if (state.calibers.length === 0) return true

      const fullCalibers = calibers.map((cal: any) => cal.Name)

      return state.calibers.some((r: any) => fullCalibers.includes(r))
    })

    if (!isShotShellPage) {
      filteredData = filteredData.filter((product: any) => {
        const { bullet_type } = product.node

        if (bullet_type === null) return false
        if (state.bullet_type.length === 0) return true
        return state.bullet_type.includes(bullet_type.name)
      })
    }
    if (isShotShellPage) {
      filteredData = filteredData.filter((product: any) => {
        const { shot_size } = product.node

        if (shot_size === null) return false
        if (state.shot_size.length === 0) return true
        return state.shot_size.includes(shot_size.name)
      })

      filteredData = filteredData.filter((product: any) => {
        const { ballistics } = product.node

        if (ballistics === null) return false
        if (state.velocity.length === 0) return true

        const fullBallistics = ballistics.map((ball: any) =>
          String(ball.velocity)
        )

        return state.velocity.some((r: any) => fullBallistics.includes(r))
      })
    }

    filteredData = filteredData.filter((product: any) => {
      const { applications } = product.node

      if (applications === null) return false
      if (state.application.length === 0) return true

      const fullApplications = applications.map((app: any) => app.name)

      return state.application.some((r: any) => fullApplications.includes(r))
    })

    filteredData = filteredData.filter((product: any) => {
      const { weight } = product.node

      if (weight === null) return false
      if (state.weight.length === 0) return true
      return state.weight.includes(weight.name)
    })

    return filteredData
  }, [state])

  return (
    <Layout
      seo={{
        metaTitle: "Ammunitions",
        metaDescription: "Ammunitions Parent Page",
      }}
      location={props.location}
    >
      <CategoryHero
        title={data.strapiCategory?.name as string}
        text={data.strapiCategory?.categoryIntro as string}
        bulletImage={
          data.strapiCategory?.bulletImage?.localFile?.childImageSharp
            ?.gatsbyImageData
        }
        bulletImageAlt={
          data.strapiCategory?.bulletImage?.alternativeText as string
        }
        hasLocatorCTA
      />

      <AmmoFilter
        currentFilters={state.allFilters}
        dispatch={dispatch}
        filters={FilterData}
        viewButtonToggle={displayList}
        viewButtonClickOnClick={handleDisplayChange}
      />

      {filteredProducts.length === 0 && (
        <Box as="section">
          <Container sx={{ variant: "layout.wide" }}>
            <Box sx={{ overflowX: "auto", width: "100%" }}>
              <Heading
                as="h4"
                variant="subtitle"
                sx={{ textAlign: "center", mb: 12 }}
              >
                Whoops, looks like we don't have a product that matches this
                description right now. To explore what we do have in our
                inventory, try removing certain filters.
              </Heading>
            </Box>
          </Container>
        </Box>
      )}

      {displayList && filteredProducts.length !== 0 && (
        <Box as="section">
          <Container sx={{ variant: "layout.wide" }}>
            <Box sx={{ overflowX: "auto", width: "100%" }}>
              <Themed.table
                sx={{
                  width: "964px",
                  "& td, & th": {
                    width: isShotShellPage ? "20%" : "25%",
                  },
                  "& td:nth-of-type(1), & th:nth-of-type(1)": {
                    width: isShotShellPage ? "20%" : "28%",
                  },
                  "& td:nth-of-type(3), & th:nth-of-type(3)": {
                    width: isShotShellPage ? "20%" : "13%",
                  },
                }}
              >
                <thead sx={{ "& th": { pb: 8 } }}>
                  <tr>
                    <Heading as="th" variant="productTableHeading">
                      {isShotShellPage ? "Gauge" : "Caliber"}
                    </Heading>
                    <Heading as="th" variant="productTableHeading">
                      {isShotShellPage ? "shot size" : "Bullet Type"}
                    </Heading>

                    <Heading as="th" variant="productTableHeading">
                      weight
                    </Heading>
                    {isShotShellPage && (
                      <Heading as="th" variant="productTableHeading">
                        velocity
                      </Heading>
                    )}
                    <Heading as="th" variant="productTableHeading">
                      Application
                    </Heading>
                  </tr>
                </thead>
                <tbody
                  sx={{
                    "& td": {
                      py: 8,
                      pl: 5,
                    },
                    "& > tr:nth-of-type(2n-1)": {
                      "& > td": {
                        backgroundColor: "lightTan",
                      },
                    },
                    "& tr:hover": {
                      td: {
                        backgroundColor: "tan",
                      },
                    },
                  }}
                >
                  {filteredProducts.map((product: any, index: number) => (
                    <tr key={index}>
                      <Themed.td>
                        <AguilaButton
                          variant="textOnly"
                          url={`/products/${product?.node?.slug}`}
                          text={product?.node?.title as string}
                        />
                      </Themed.td>
                      <Themed.td>
                        {isShotShellPage
                          ? product?.node?.shot_size === null
                            ? "-"
                            : product?.node?.shot_size?.name
                          : product?.node?.bullet_type?.name}
                      </Themed.td>
                      <Themed.td>{product.node?.weight?.name || "-"}</Themed.td>
                      {isShotShellPage && (
                        <Themed.td>
                          {product.node?.ballistics[0]?.velocity || "-"}
                        </Themed.td>
                      )}
                      <Themed.td>
                        {product?.node?.applications.length === 0
                          ? "-"
                          : product?.node?.applications.map(
                              (app: any, index: number) => (
                                <span key={index} sx={{ display: "block" }}>
                                  &#8226; {app.name}
                                </span>
                              )
                            )}
                      </Themed.td>
                    </tr>
                  ))}
                </tbody>
              </Themed.table>
            </Box>
          </Container>
        </Box>
      )}

      {!displayList && filteredProducts.length !== 0 && (
        <Box as="section">
          <Container>
            <Grid
              sx={{
                gap: 5,
                gridTemplateColumns: [
                  "1fr",
                  "1fr 1fr",
                  "1fr 1fr 1fr",
                  "1fr 1fr 1fr 1fr",
                  null,
                ],
              }}
            >
              {filteredProducts.map((product: any) => {
                return (
                  <ProductCard
                    weight={product.node?.weight?.name || "-"}
                    key={product?.node?.slug}
                    image={
                      product?.node?.product_images.length > 0
                        ? product?.node?.product_images[0]?.localFile
                            ?.childImageSharp?.gatsbyImageData
                        : null
                    }
                    imageAlt={
                      product?.node?.product_images.length > 0
                        ? product?.node?.product_images[0]?.alternativeText
                        : ""
                    }
                    showImage
                    productTitle={product?.node?.title as string}
                    productUrl={`/products/${product?.node?.slug}`}
                    bulletType={product?.node?.bullet_type?.name as string}
                    shotSize={product?.node?.shot_size?.name as string}
                    application={
                      product?.node?.applications.length === 0
                        ? "-"
                        : (product?.node?.applications
                            ?.map((app: any) => app.name)
                            .join(", ") as string)
                    }
                    isShotShell={isShotShellPage}
                  />
                )
              })}
            </Grid>
          </Container>
        </Box>
      )}

      <Box as="section" sx={{ my: 12, mb: 18 }}>
        <SectionHeading indented>
          {data.strapiCategory?.additionalContentTitle}
        </SectionHeading>
        <Container variant="layout.wide">
          <MdContent source={data.strapiCategory?.additionalContent} />
        </Container>
      </Box>
    </Layout>
  )
}

export default AmmoCategory

export const pageQuery = graphql`
  query AmmoCategoryTemplate($slug: String!) {
    allStrapiProduct(
      filter: { categories: { elemMatch: { slug: { eq: $slug } } } }
      sort: { fields: order, order: ASC }
    ) {
      edges {
        node {
          slug
          title
          bullet_type {
            name
            id
          }
          weight {
            name
            id
          }
          calibers {
            id
            Name
          }
          ballistics {
            velocity
          }
          shot_size {
            name
            id
          }
          applications {
            name
            id
          }
          product_images {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    UniqueVelocities: allStrapiProduct(
      filter: { categories: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      edges {
        node {
          id
          ballistics {
            velocity
          }
        }
      }
      distinct(field: ballistics___velocity)
    }
    UniqueApplications: allStrapiProduct(
      filter: { categories: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      distinct(field: applications___name)
    }
    UniqueShotSizes: allStrapiProduct(
      filter: { categories: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      distinct(field: shot_size___name)
    }
    UniqueCalibers: allStrapiProduct(
      filter: { categories: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      distinct(field: calibers___Name)
    }
    UniqueBulletTypes: allStrapiProduct(
      filter: { categories: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      distinct(field: bullet_type___name)
    }
    UniqueWeights: allStrapiProduct(
      filter: { categories: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      distinct(field: weight___name)
    }
    strapiCategory(slug: { eq: $slug }) {
      slug
      name
      categoryIntro
      bulletImage {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      additionalContentTitle
      additionalContent
    }
  }
`
