/** @jsx jsx */
import { jsx, Box, Text } from "theme-ui"
import { useState, createRef } from "react"

import Arrow from "components/common/Arrow"
import CompareCheckbox from "components/common/CompareCheckbox"

interface DropDownFilterProps {
  checkBoxName: string
  className?: string
  filterName: string
  filterOptions: { id: string; name: string }[]
  checkboxOnChange: React.ChangeEventHandler<HTMLInputElement>
  mobile: boolean
  currentFilters: any
}

const DropDownFilter = ({
  checkBoxName,
  className,
  filterName,
  filterOptions,
  checkboxOnChange,
  mobile,
  currentFilters,
}: DropDownFilterProps) => {
  const [open, setOpen] = useState(false)
  const [height, setHeight] = useState("0px")

  const list = createRef<HTMLDivElement>()

  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(!open)
    setHeight(height === "0px" ? `${list?.current?.scrollHeight}px` : "0px")
  }

  const handleCheckBoxClick = () => {
    if (!mobile) {
      setOpen(false)
      setHeight("0px")
    }
  }

  const isChecked = (name: string) => {
    const found = currentFilters.find((e: any) => e.value === name)
    return found ? true : false
  }

  return (
    <Box
      className={className}
      sx={{
        py: [5, null, null, 0],
        position: [null, null, null, "relative"],
        // ":focus-within": {
        //   [`#${checkBoxName}-list`]: {
        //     height: "auto",
        //     opacity: 1,
        //   },
        // },
      }}
    >
      <button
        type="button"
        aria-haspopup="listbox"
        id={`${checkBoxName}-btn`}
        aria-labelledby={`${checkBoxName}-btn ${checkBoxName}-list`}
        onClick={onClick}
        sx={{
          py: 0,
          pl: 0,
          pr: 2,
          cursor: "pointer",
          backgroundColor: "transparent",
          border: "none",
          color: "text",
        }}
      >
        <Text
          variant="subtitle"
          sx={{
            color: "text",
            fontFamily: ["body", null, null, "heading"],
            fontWeight: ["bold", null, null, "normal"],
          }}
        >
          {filterName}
        </Text>
        <Arrow
          arrowColor="#A18347"
          sx={{
            transform: open ? "rotate(90deg) scaleX(-1)" : "rotate(90deg)",
            ml: 5,
          }}
        />
      </button>

      <hr
        sx={{
          display: ["block", null, null, "none"],
          border: "none",
          height: "1px",
          backgroundColor: "text",
          mt: 3,
          mb: 4,
        }}
      />

      <Box
        aria-labelledby={`${checkBoxName}-btn`}
        as="ul"
        id={`${checkBoxName}-list`}
        ref={list}
        role="listbox"
        sx={{
          position: [null, null, null, "absolute"],
          top: [null, null, null, "49px"],
          left: [null, null, null, 0],
          zIndex: [null, null, null, 15],
          backgroundColor: [null, null, null, "lightTan"],
          height,
          maxHeight: [null, null, null, "300px"],
          listStyleType: "none",
          overflow: ["hidden", null, null, "auto"],
          px: [3, null, null, 4],
          py: [null, null, 2],
          // ":focus-within": {
          //   height: "auto",
          //   opacity: 1,
          // },
          boxShadow: [null, null, null, "0px 8px 16px 0px rgba(0,0,0,0.2)"],
          transition: [
            "height 0.3s ease, opacity 0.3s ease",
            null,
            null,
            "none",
          ],
          opacity: open ? 1 : 0,
        }}
      >
        {filterOptions.map((filter, index) => (
          <li
            key={filter.id}
            role="option"
            sx={{
              my: 4,
            }}
          >
            <CompareCheckbox
              checked={isChecked(filter.name)}
              id={filter.id}
              name={checkBoxName}
              onChange={e => {
                checkboxOnChange(e)
                handleCheckBoxClick()
              }}
              small
              text={filter.name}
              value={filter.name}
            />
          </li>
        ))}
      </Box>
    </Box>
  )
}

export default DropDownFilter
