/** @jsxRuntime classic */
/** @jsx jsx */
import { Flex, Text, jsx } from "theme-ui"

interface AmmoFilterTagProps {
  as: React.ElementType<any> | undefined
  className?: string
  onClick: React.MouseEventHandler<HTMLButtonElement>
  tagName: string
}

const AmmoFilterTag = ({
  as,
  className,
  onClick,
  tagName,
}: AmmoFilterTagProps) => {
  return (
    <Flex
      className={className}
      as={as}
      sx={{
        alignItems: "center",
        backgroundColor: "lightTan",
        m: 2,
        px: 3,
        py: 2,
      }}
    >
      <span
        sx={{
          color: "text",
          fontFamily: "body",
          fontSize: ["10px", null, null, "14px"],
          lineHeight: "100%",
          textTransform: "uppercase",
        }}
      >
        {tagName}
      </span>

      <button
        type="button"
        onClick={onClick}
        sx={{
          backgroundColor: "transparent",
          border: "none",
          cursor: "pointer",
          ml: 3,
          p: 0,
        }}
      >
        <Text variant="styles.srOnly">{`Remove ${tagName} filter option`}</Text>
        <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.75011 0L4.99956 3.75L1.24989 0L0 1.25L3.74967 5L0 8.75L1.24989 10L4.99956 6.25L8.75011 10L10 8.75L6.25033 5L10 1.25L8.75011 0Z"
            fill="#A18347"
          />
        </svg>
      </button>
    </Flex>
  )
}

export default AmmoFilterTag
