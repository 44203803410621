/** @jsx jsx */
import { Button, jsx } from "theme-ui"

import ListIcon from "../images/icons/list-icon.svg"
import CardsIcon from "../images/icons/cards-icon.svg"

interface ListViewButtonProps {
  //enables sx prop to be passed down from parent
  className?: string
  toggle: boolean
  onClick: () => void
}

const ListViewButton = ({
  className,
  toggle,
  onClick,
}: ListViewButtonProps) => {
  return (
    <Button
      className={className}
      type="button"
      aria-label="change view"
      onClick={onClick}
      sx={{ px: 4, display: "inline-flex", alignItems: "center" }}
    >
      view
      {!toggle && <ListIcon sx={{ width: "18px", height: "18px", ml: 3 }} />}
      {toggle && <CardsIcon sx={{ width: "18px", height: "18px", ml: 3 }} />}
    </Button>
  )
}

export default ListViewButton
