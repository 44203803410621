/** @jsx jsx */
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Text,
  ThemeUICSSObject,
  jsx,
} from "theme-ui"
import { useEffect, useState } from "react"

import Arrow from "components/common/Arrow"
import DropDownFilter from "components/ammo-filter/DropdownFilter"
import FilterTag from "components/ammo-filter/FilterTag"
import ListViewButton from "components/ListViewButton"

type AmmoFilter = {
  name: string
  id: string
  filterOptions: { id: string; name: string }[]
}

interface AmmoFilterProps {
  className?: string
  filters: AmmoFilter[]
  viewButtonClickOnClick: () => void
  viewButtonToggle: boolean
  dispatch: any
  currentFilters: { value: string; filterName: string }[]
}

const AmmoFilter = ({
  className,
  viewButtonToggle,
  filters,
  viewButtonClickOnClick,
  dispatch,
  currentFilters,
}: AmmoFilterProps) => {
  const [mobileFilterOpen, setMobileFilterOpen] = useState(false)

  useEffect(() => {
    let timeout: NodeJS.Timer
    const handleResize = () => {
      window.matchMedia("(max-width: 978px)").matches &&
        setMobileFilterOpen(false)
    }

    const debouncedHandleResize = () => {
      clearTimeout(timeout)
      timeout = setTimeout(handleResize, 300)
    }

    window.addEventListener("resize", debouncedHandleResize)

    return () => window.removeEventListener("resize", debouncedHandleResize)
  }, [])

  useEffect(() => {
    document.body.style.overflow = mobileFilterOpen ? "hidden" : "auto"
  }, [mobileFilterOpen])

  const clearFilter = () => {
    dispatch({ type: "clearFilter" })
  }
  const openMobileFilter = () => {
    setMobileFilterOpen(true)
  }
  const closeMobileFilter = () => {
    setMobileFilterOpen(false)
  }
  const checkBoxOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked

    if (checked) {
      dispatch({
        type: "addFilter",
        filterName: e.target.name,
        value: e.target.value,
      })
    } else {
      dispatch({
        type: "removeFilter",
        filterName: e.target.name,
        value: e.target.value,
      })
    }
  }

  const mobileNavStyles: ThemeUICSSObject = mobileFilterOpen
    ? {
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        left: 0,
        position: "fixed",
        px: 3,
        py: 7,
        top: 0,
        width: "100%",
        zIndex: "15",
      }
    : {}

  return (
    <Box>
      <Container variant="wide">
        <Flex
          sx={{
            borderBottomColor: "text",
            borderBottomStyle: "solid",
            borderBottomWidth: "1px",
            pb: 3,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <button
            type="button"
            onClick={openMobileFilter}
            sx={{
              display: ["inline-flex", null, null, "none"],
              alignItems: "center",
              backgroundColor: "transparent",
              border: "none",
              cursor: "pointer",
              p: 0,
              color: "text",
            }}
          >
            <Text variant="subtitle" sx={{ color: "text" }}>
              Filter
            </Text>
            <Arrow
              arrowColor="#A18347"
              sx={{
                ml: 5,
              }}
            />
          </button>

          <Box
            sx={{
              display: ["none", null, null, "flex"],
              flex: [null, null, null, 1],
              ...mobileNavStyles,
            }}
          >
            <Flex
              sx={{
                display: ["flex", null, null, "none"],
                justifyContent: "space-between",
                alignItems: "center",
                pb: 3,
                borderBottomColor: "text",
                borderBottomWidth: "1px",
                borderBottomStyle: "solid",
                // height: "39px",
              }}
            >
              <Heading as="h3">Filter</Heading>

              <button
                onClick={closeMobileFilter}
                type="button"
                sx={{
                  p: 0,
                  cursor: "pointer",
                  border: "none",
                  backgroundColor: "transparent",
                  mr: 3,
                }}
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.7502 0L8.9992 6.75L2.2498 0L0 2.25L6.7494 9L0 15.75L2.2498 18L8.9992 11.25L15.7502 18L18 15.75L11.2506 9L18 2.25L15.7502 0Z"
                    fill="#A18347"
                  />
                </svg>
              </button>
            </Flex>

            <Box
              as="ul"
              sx={{
                display: ["block", null, null, "flex"],
                // height: "calc(100% - 93px)",
                flex: 1,
                overflow: ["auto", null, null, "visible"],
                listStyleType: "none",
                justifyContent: [null, null, null, "space-between"],
                px: [4, null, null, 0],
                py: [8, null, null, 0],
                gap: 3,
              }}
            >
              {filters.map((filter, index) => (
                <li
                  key={index}
                  sx={{
                    flex: [
                      null,
                      null,
                      null,
                      "auto",
                      // `0 0 calc(100% / ${filters.length})`,
                    ],
                  }}
                >
                  <DropDownFilter
                    currentFilters={currentFilters}
                    mobile={mobileFilterOpen}
                    checkboxOnChange={checkBoxOnChange}
                    checkBoxName={filter.id}
                    filterName={filter.name}
                    filterOptions={filter.filterOptions}
                  />
                </li>
              ))}
            </Box>

            <Box
              sx={{
                // height: "54px",
                px: 6,
                display: ["block", null, null, "none"],
              }}
            >
              <Button
                onClick={closeMobileFilter}
                type="button"
                variant="secondary"
                sx={{ width: "100%" }}
              >
                apply
              </Button>
            </Box>
          </Box>

          <Box>
            <ListViewButton
              toggle={viewButtonToggle}
              onClick={viewButtonClickOnClick}
            />
          </Box>
        </Flex>

        <Flex
          sx={{
            justifyContent: "space-between",
            pt: [3, null, null, 4],
            mb: 14,
          }}
        >
          <Flex sx={{ flexWrap: "wrap" }}>
            {currentFilters.map((item, index) => (
              <FilterTag
                as="span"
                onClick={() => {
                  dispatch({
                    type: "removeFilter",
                    filterName: item.filterName,
                    value: item.value,
                  })
                }}
                tagName={item.value}
                key={index}
              />
            ))}
          </Flex>

          <Box sx={{ pl: 3, flexShrink: 0 }}>
            <button
              onClick={clearFilter}
              type="button"
              sx={{
                backgroundColor: "transparent",
                border: "none",
                color: "text",
                cursor: "pointer",
                fontFamily: "body",
                fontSize: ["10px", null, null, "14px"],
                p: 0,
                textTransform: "uppercase",
              }}
            >
              clear all
            </button>
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}

export default AmmoFilter
